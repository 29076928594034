.text {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 36px;
  font-weight: 300;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.white {
  color: #fff;
}

.section {
  color: #0c2340;
}

@media only screen and (max-width: 1499.99px) {
  .text {
    font-size: 97%;
    line-height: 26px;
  }
}

/* @media only screen and (max-width: 580px) {
  .text {
    font-size: 90%;
  }
} */
