.mobHamburgerMenu {
  display: flex;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  justify-content: center;
  align-items: center;
}

.mobHamburgerBar {
  width: 40px;
  height: 6px;
  background-color: #13427a;
  transition: all 0.3s ease;
}

.mobHamburgerMenu:hover .mobHamburgerBar {
  background-color: #c7131e;
}

@media only screen and (min-width: 1215px) {
  .mobHamburgerMenu {
    display: none;
  }
}
