/*
the header is fixed, so consume some space so that Hero/PageTitle appears correctly
*/
.spacer {
  height: calc(51px + 60px);
}

.wrapper {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 999;
}
/* 
.innerWrapper {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
} */

.top {
  height: 51px;
  background: #2a3e57;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bottom {
  width: 100%;
  background: #fff;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 3px 20px #00000029;
  column-gap: 25px;
}

.user {
  position: relative;
  height: 100%;
  display: flex;
  margin-right: 32px;
}

.userButton {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.username {
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.arrow {
  margin-left: 8px;
  cursor: pointer;
  user-select: none;
}

.userButton:hover .username,
.userButton:hover .arrow {
  color: #c7131e;
  fill: #c7131e;
}

.dropdown {
  position: absolute;
  background: white;
  top: 51px;
  right: 0;
  width: 200px;
  border: 1px solid 2a3e57;
  border-top: none;
  box-shadow: 0 3px 20px #00000029;
  z-index: 1;
}

.dropdownLink {
  text-decoration: none;
}

.item {
  cursor: pointer;
  padding: 8px;
  user-select: none;
  text-decoration: none;
  color: black;
}
.item:hover {
  color: #c7131e;
}

.button {
  margin: auto 32px;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ctaContainer {
  height: 100%;
  text-decoration: none;
}

.rightNavContainer {
  height: 100%;
  display: flex;
}

.label {
  color: #fff;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  margin-left: 5px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.logo {
  width: 100%;
  max-width: 260px;
  height: auto;
}

.navigation {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
  column-gap: 15px;
}

.item {
  position: relative;
}

.link {
  font-size: 15px;
  color: #0c2340;
  font-family: Montserrat, sans-serif;
  transition: 0.5ms;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  transition: 200ms;
}

.link:hover {
  color: #c7131e;
}

.link.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  background: #d22524;
  height: 3px;
  width: 50%;
}

.subnav {
  position: absolute;
  left: 0;
  top: 100%;
  background: #d22524;
  padding: 20px;
}

.subitem {
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 37px;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  transition: 200ms;
  padding: 0 15px
}

.subitem:hover {
  color: #555b6d;
}

.subitem.active {
  color: #555b6d;
  background-color: white;
  border-radius: 3px;
}

.subitem.active:hover {
  color: #d22524;
}


/**
 * mobile navigation pop out
 */
.mobileNavigation {
  height: 100vh;
  position: fixed;
  width: 60%;
  right: -60%;
  z-index: 10000;
  background-color: #2a3e57;
  transition: right ease-in-out 300ms;
}

.mobileNavigationActive {
  right: 0;
}

.mobileNavigationClose {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  user-select: none;
}

.mobileNavigationItems {
  overflow-y: auto;
  position: absolute;
  top: 56px;
  bottom: 80px;
  width: 100%;
  text-align: center;
}

.mobileLink {
  color: white;
}

.mobileLink:after {
  display: none;
}

.mobileSubitem {
  margin-left: 10px;
  /* margin-bottom: 15px; */
  margin-top: 15px;
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  text-decoration: none;
  transition: 200ms;
  padding: 0 15px;
  text-align: left;
}

.mobileSubitem:hover {
  color: #c7131e;
}

.ctaMobile {
  text-decoration: none;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}


@media only screen and (max-width: 1599.99px) {
  .link {
    font-size: 97%;
    line-height: 20px;
    padding: 0 7px;
    margin: 0 5px;
  }

  .link.active::after {
    width: 50px;
    left: 10px;
  }
}

@media only screen and (max-width: 1430px) {
  .link {
    font-size: 77%;
    line-height: 17px;
  }

  .label {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1300px) {
  .wrapper {
    display: block;
    position: relative;
  }

  .bottom {
    justify-content: space-between;
  }

  .spacer {
    display: none;
  }

  .navigation {
    column-gap: 5px;
  }
}

@media only screen and (max-width: 1214.9px) {
     
  .logoLink {
    max-width: 185px;
  }

  .navigation {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .ctaContainer {
    display: none;
  }
}

 @media only screen and (max-width: 580px) {
  .mobileNavigation {
    width: 100%;
    right: -100%;
  }
 
  .mobileNavigationActive {
    right: 0;
  } 
}

@media only screen and (max-width: 480px) {

  .button {
    margin: auto 20px;
  }

  .label {
    font-size: 13px;
  }
}

@media only screen and (max-width: 400px) {
  .button {
    margin: auto 10px;
  }
  
  .label {
    font-size: 10px;
  }

}

@media only screen and (max-width: 350px) {
  .button {
    margin: auto 10px;
  }
}
