.container  {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  line-height: 1.2;
  font-family: Montserrat, sans-serif;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
}

.count {
  color: #0c2340;
  line-height: 1.3;
  padding: .6rem 0;
}

.total {
  font-weight: bold;
}
