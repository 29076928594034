.item {
  font-size: 20px;
  line-height: 36px;
  margin-bottom: 8px;
  font-weight: 300;
  color: #0c2340;
  font-family: Montserrat, sans-serif;
  position: relative;
}

.unordered .item::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: -1em;
  top: 0.5em;
  width: 0.6em;
  height: 0.6em;
  border-radius: 0.3em;
  background: #d22524;
}

.list {
  padding-left: 1.5em;
}

.ordered {
  list-style: arabic;
}

.unordered {
  list-style: none;
}

@media only screen and (max-width: 1499.99px) {
  .item {
    font-size: 97%;
    line-height: 26px;
  }
}