.section {
  width: 100%;
  height: auto;
  /* 51px for the register/login bar, 60px for the nav bar */
  min-height: calc(100vh - 51px - 60px);
  background-size: cover;
  position: relative;
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
}

.overlay {
  min-height: calc(100vh - 51px - 60px);
  width: 100%;
  background-color: #2a3e57c7;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 2;
}

.heroWrapper {
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 80px 45px 180px 45px;
}

.container {
  width: 100%;
  margin: auto;
  word-break: break-word;
}

.header {
  width: 55%;
  position: relative;
  margin-bottom: 18px;
}

.header::before {
  content: '';
  display: block;
  position: absolute;
  width: 4px;
  height: 100%;
  left: -30px;
  margin: 5px 0;
  background: #c7131e;
}

.tagline {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
}

.title {
  color: #fff;
  font-size: 60px;
  line-height: 60px;
  margin: 0;
  font-weight: bold;
  font-family: Montserrat, sans-serif;
}

.text {
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 27px;
  max-width: 800px;
  font-family: Montserrat, sans-serif;
}

.ctaWrapper {
  padding-top: 30px;
  display: block;
}

.cta {
  padding: 30px 40px;
  background: #d22524;
  border-radius: 9px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  text-decoration: none;
  transition: 0.5s;
}

.cta:hover {
  opacity: 0.9;
}

.ctaPortalWrapper {
  max-width: 800px;
  display: flex;
  width: 800px;
}

.portalWrapper {
  margin-top: 110px;
  margin-left: 155px;
  position: absolute;
}

.portal {
  position: relative;
  margin-top: 18px;
  display: block;
}

.portalText {
  position: absolute;
}

.portalArrow {
  position: absolute;
  left: 205px;
  bottom: -35px;
}

.portalButton {
  position: absolute;
  left: 265px;
  bottom: 15px;
}

.scrollWrapper {
  width: 100%;
  height: auto;
}

.scroll {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1215px) {
  .portal, .scroll {
    display: none;
  }
}

@media only screen and (max-width: 1124px) {
  .container {
    display: grid;
    row-gap: 30px;
  }
  .header, .text {
    width: auto;
    max-width: 80%;
    margin: 0 auto;
  }

  .ctaPortalWrapper{
    text-align: center;
    display: grid;
    width: 100%;
  }

  .portalWrapper {
    display: none;
  }
  
}

@media only screen and (max-width: 768px) {
  
  .header, .text {
    max-width: 100%;
  }

  .title {
    font-size: 55px;
    line-height: 55px;
  }
  
  .portalText, .portalArrow, .portalButton {
    position: relative;
    bottom: 0;
    left: 0;
  }

}

@media only screen and (max-width: 680px) {
  .portalText {
    width: 125px;
  }
  
  .portalArrow {
    width: 50px;
  }

  .portalButton {
    width: 50px;
    margin-top: -32px;
    margin-left: -2px;
  }

}

@media only screen and (max-width: 480px) {
  .title {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 350px) {
  .title {
    font-size: 25px;
    line-height: 30px;
  }

  .portal {
    display: grid;
    row-gap: 20px;
    text-align: center;
  }

  .portalButton {
    margin-top: 0;
    margin-left: 15px;
    width: 100px;
    margin-bottom: 40px;
  }

  .portalArrow {
    display: none;
  }
}
