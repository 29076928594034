.container {
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.panel {
  width: 100%;
  background: #fff;
  border-radius: 7px;
  border: 1px solid #f2f3f5;
}

.top {
  width: 100%;
  box-sizing: border-box;
  padding: 23px 24px 23px 30px;
  border-bottom: 1px solid #f2f3f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.title {
  color: #0c2340;
  font-size: 22px;
  font-family: Montserrat, sans-serif;
  transition: color 500ms;
}

.arrow {
  width: auto;
  height: 22px; /* width is height because rotation */
  transform: rotate(90deg);
  transition: 500ms;
  fill: #0c2340;
}

.activeTitle {
  color: #c7131e;
}

.activeArrow {
  transform: rotate(-90deg);
  fill: #c7131e;
}

.content {
  color: #707070;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  line-height: 28px;
  max-height: 0;
  overflow: hidden;
  transition: 500ms;
}

.activeContent {
  /* not intended to be an actual limit, just for the transition */
  max-height: 1000px;
}

.text {
  margin: 30px 21px;
}

@media only screen and (max-width: 980px) { 
  .title {
    font-size: 16px;
    line-height: 22px;
  }
}
