.sectionContainer {
  width: 100%;
  height: auto;
  background-color: #0c2340cc;
  position: relative;
  /*background-image: url('../../../public/images/request_callback_bg.png');*/
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.overlay {
  display: flex;
  background-color: #0c23408f;
}

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 35px;
  align-content: center;
  column-gap: 30px;
}

.formWrapper {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

.columns > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 300px;
}

.title {
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  background: #004aa1;
  text-align: center;
  padding: 30px 15px;
  border-radius: 15px 15px 0 0;
}

.formContainer {
  width: 100%;
  max-width: 1000px;
}
.form {
  background: #fff;
  border-radius: 0 0 15px 15px;
  text-align: center;
  padding: 20px 38px 45px;
}

.field {
  width: 100%;
  height: 55px;
  margin-bottom: 10px;
  padding-left: 1em;
  border-radius: 5px;
  background: #f7f7f6;
  border: 2px solid #e6e6e6;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  box-sizing: border-box;
}

.select {
  padding-right: 1em;
}

.select:invalid {
  color: #7f7f7f;
}

.message {
  height: 100%;
}

.captcha {
  margin: 0 auto;
}

.submit {
  width: auto;
  height: auto;
  display: inline-block;
  margin-top: 18px;
  /* height: 75px;
  width: 360px; */
  background: #004aa1;
  border-radius: 9px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  border: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: 300ms;
  padding: 20px;
}

.submit:hover {
  opacity: 0.9;
}

@media only screen and (max-width: 1430px) {
  .title, .submit {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .columns {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .title, .submit, .field, .select {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
  /* .formWrapper, .playBTNWrapper {
    width: 100%;
    display: block;
    margin: 0 auto;
  } */
  .play {
    margin: 0 auto;
  }
  .submit {
    font-size: 13px;
  }
}

@media only screen and (max-width: 600px) {
  /* .container {
    display: grid;
    row-gap: 60px;
    justify-content: center;
  } */
  .title {
    font-size: 18px;
  } 
}

@media only screen and (max-width: 600px) {
  .form {
    padding: 20px 11px 39px;
  }
}
