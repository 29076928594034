.container {
  height: 100%;
  position: relative;
}

.input {
  width: calc(100% - 47px);
  height: 100%;
  padding: 0.6em 1rem;
  border-radius: 5px 0 0 5px;
  background: #f7f7f6;
  border: 2px solid #e6e6e6;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  box-sizing: border-box;
  color: #999;
}

.icon {
  width: 47px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  background: #e6e6e6;
  border-radius: 0 5px 5px 0;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
