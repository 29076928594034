.container {
  padding: 80px 35px;
}

.form {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  padding: 0.5rem 0 1.5rem;
}

.subtitle {
  font-size: 22px;
  font-family: Montserrat, sans-serif;
  color: #0c2340;
  margin-top: 26px;
  margin-bottom: 52px;
}

.fields {
  display: flex;
  flex-direction: column;
  column-gap: 74px;
  row-gap: 37px;
}

.field {
  width: 100%;
}

.label {
  display: block;
  color: #949ca5;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  margin-bottom: 12px;
}

.input {
  width: 100%;
  background: #f7f7f6;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  padding: 22px 16px;
  box-sizing: border-box;
}

.error {
  color: #c7131e;
}

.remember {
  display: flex;
  align-items: center;
}

.remember .label {
  margin-bottom: 0;
  margin-left: 10px;
}

.checkbox {
  width: 26px;
  height: 26px;
  display: inline-block;
}

.submit {
  background: #004aa1;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 13px 25px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  transition: 300ms;
}

.submit:hover {
  opacity: 0.9;
}

.login {
  font-family: Montserrat, sans-serif;
  color: #818181;
  margin-top: 1em;
  display: block;
  text-decoration: none;
}

.join {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #818181;
}

.link {
  color: #fc0000;
  text-decoration: none;
}

@media only screen and (max-width: 580px) {
  .submit {
    max-width: 200px;
  }

  .submitAndPassword {
    display: grid;
    row-gap: 25px;
  }

  .forgot {
    margin: 0;
  }
}
