.input {
  width: 100%;
  height: auto;
  padding: .6em 1rem;
  border-radius: 5px;
  background: #f7f7f6;
  border: 2px solid #e6e6e6;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  box-sizing: border-box;
  color: #999;
}
