.container {
  background: #c7131e;
  width: 100%;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  height: 266px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.left {
  display: inline-block;
}

.title {
  color: #fff;
  font-size: 33px;
  font-weight: bold;
  margin-bottom: 21px;
}

.tagline {
  color: #fff;
  line-height: 30px;
}

.divider {
  display: inline-block;
  width: 1px;
  height: 52px;
  background: #fff;
}

.right {
  display: inline-block;
}

.phone {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.phoneIcon {
  display: inline-block;
  margin-right: 13px;
}

.phoneText {
  display: inline-block;
  color: #fff;
  font-size: 29px;
  font-weight: 600;
}

.button {
  background: #fff;
  border-radius: 5px;
  height: 59px;
  width: 257px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #c7131e;
}


@media only screen and (max-width: 1100px) {
  .title {
    font-size: 23px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 980px) {
  .wrapper {
    height: auto;
    padding: 50px 25px;
  }
  .divider {
    margin: 0 15px;
    height: 100px;
  }
}

@media only screen and (max-width: 780px) {
  .wrapper {
    display: grid;
    row-gap: 30px;
    text-align: center;
  }
  .right {
    display: grid;
    text-align: center;
  }
  .phone  {
    display: block;
    text-align: center;
  }
  .button {
    margin: 0 auto;
  }
  .divider {
    display: none;
  }

}