.container {
  min-width: 250px;
  max-width: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  margin-bottom: 20px;
}

.photo {
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.label {
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
}

.name {
  font-size: 26px;
  color: #0C2340;
  line-height: 35px;
  text-transform: capitalize;
  line-height: 1.2;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.position {
  font-size: 16px;
  color: #7e97b6;
  line-height: 25px;
  text-transform: capitalize;
  line-height: 1.2;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}
