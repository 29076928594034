.bodyWrapper {
  width: 100%;
  display: flex;
  position: relative;
}
.innerBodyWrapper {
  width: 100%;
  display: flex;
  /* overflow-x: hidden; */
}
.container {
  height: 100%;
  width: 100%;
  display: flex;
}

.left {
  width: 50%;
  height: auto;
  background-image: url('../../../public/images/houses.png');
  background-size: cover;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: #2a3e57de;
  padding: 0 5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contentWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info {
  display: grid;
  row-gap: 25px;
  margin-top: 19px;
  width: 70%;
  margin: 0 auto;
}

.text {
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  display: block;
  line-height: 32px;
  color: #fff;
}

.link {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: #fff;
}

.form {
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  background-color: #fff;
  padding: 5.5rem 11rem 6.5rem 5rem;
  box-sizing: border-box;
}

.label {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #949ca5;
}

.required {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #fc0000;
}

.field {
  width: 100%;
  height: 42px;
  margin-top: 12px;
  margin-bottom: 33px;
  padding-left: 1em;
  border-radius: 5px;
  background: #f7f7f6;
  border: 2px solid #e6e6e6;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  box-sizing: border-box;
}

.textarea {
  height: 188px;
}

.submit {
  display: inline-block;
  height: 65px;
  width: 250px;
  background: #004aa1;
  border-radius: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  border: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: 300ms;
}

.submit:hover {
  opacity: 0.9;
}

.captchaWrapper {
  margin-bottom: 33px;
}

@media only screen and (max-width: 1120px) {
  .container {
    display: grid;
  }
  .contentWrapper {
    padding: 80px 0;
  }
  .overlay {
    padding: 0;
  }
  .left,
  .form {
    width: 100%;
  }
  .form {
    padding: 80px 35px;
  }
}
