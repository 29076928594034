.container  {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  line-height: 1.2;
  font-family: Montserrat, sans-serif;
  padding: 80px 0;
}

.chosenItemWraper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 35px;
}

.placeholderFieldItem {
  width: 100%;
}

.optionLabel {
  text-transform: uppercase;
  color: #0C2340;
  line-height: 1.3;
  margin-bottom: 3px;
}

.optionValue {
  font-weight: 800;
  color: #0C2340;
}

.heading {
  font-size: 20px;
  color: #0C2340;
  font-weight: 600;
}

.spacer {
  width: 100%;
  height: 15px;
  display: block;
}

.amount {
  display: flex;
  gap: 15px;
  align-items: flex-end;
}

.price {
  font-size: 25px;
  font-weight: 700;
  color: #D22524;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0 1rem 0;
  column-gap: 50px;
}
