.track {
  height: 12px;
  border-radius: 6px;
  background: #f7f7f6;
  border: 1px solid #e6e6e6;
}

.bar {
  height: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  transition: 300ms;
  background: #4baf4f;
}
