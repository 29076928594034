.table {
  border-collapse: collapse;
}

.th {
  border: 1px solid black;
  padding: 0 4px;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.label {
  font-weight: bold;
  font-size: 18px;
}

.sorting {
  user-select: none;
  display: grid;
  grid-template-columns: 1fr;
}

.sort {
  width: 22px;
  height: 22px;
}

.cell {
  padding: 0 4px;
  font-size: 12px;
  border: 1px solid black;
}

.text {
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  color: #0c2340;
  user-select: none;
}

.link {
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: #c7131e;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
}

.button {
  margin: 5px 5px;
  border: 1px solid #ccc;
  padding: 5px;
  background-color: #eee;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: #c7131e;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}
