.container {
  width: 100%;
  /*padding: 112px 0;*/
  /*padding: 0 25px;*/

  position: relative;
}

.overflow {
  overflow: hidden;
}

.scroller {
  width: 100%;
  max-width: 1405px;
  margin: 0 auto;
  height: 552px;
  overflow-x: visible;
  position: relative;
  display: flex;
  scroll-snap-type: x mandatory;
}

.items {
  position: absolute;
  display: flex;
  flex-wrap: none;
  gap: 35px;
  transition: left 500ms;
}

.item {
  width: 445px;
  height: 552px;
  display: inline-block;
  position: relative;
  border-radius: 7px;
  cursor: pointer;
  user-select: none;
}

.overlay {
  border-radius: 7px;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: background 300ms;
}

.item:hover .overlay {
  height: 100%;
  background: #0c2340cf;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 7px;
}

.caption {
  position: absolute;
  color: #fff;
  font-size: 28px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  bottom: 60px;
  right: 24px;
  left: 24px;
  text-align: end;
}

.stroke {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.gradient {
  border-radius: 7px;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background: linear-gradient(0deg, #0c2340cf, 16%, #0c234000, 33%, #0000);
  opacity: 1;
  transition: opacity 300ms;
}

.item:hover .gradient {
  opacity: 0;
}

.arrow {
  position: absolute;
  bottom: 30px;
  right: 35px;
}

/* buttons to scroll left/right */
.button {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: #949ca5;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  top: calc(50% - 20px);
}

.prev {
  left: -40px;
}

.left {
  transform: rotate(180deg);
}

.next {
  right: -40px;
}

.active {
  background: #0c2340;
}

.fullWidth {
  width: 100%;
}

@media only screen and (max-width: 780px) {
  .container {
    padding: 70px 0;
  }

  .scroller {
    height: 414px;
  }

  .item {
    width: 333.75px;
    height: 414px;
  }

  .prev {
    left: -20px;
  }

  .next {
    right: -20px;
  }

  .button {
    width: 60px;
    height: 60px;
  }

  .button svg {
    max-width: 50%;
  }
}
