.guides {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.guide {
  width: 350px;
  height: 350px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.guide:nth-child(6n + 3) {
  margin-right: 350px;
}

.guide:nth-child(6n + 4) {
  margin-left: calc(350px + 30px);
}

.guideWrapper {
  padding: 10px 35px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: #0c2340b5;
  border-radius: 7px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 300ms;
  cursor: pointer;
  user-select: none;
}

.guide:hover .overlay {
  opacity: 1;
}

.play {
  width: 100%;
  max-width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.name {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 25px;
  color: #fff;
  position: absolute;
  bottom: 46px;
  right: 30px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupVideo {
  max-width: 75%;
  max-height: 75%;
}

@media only screen and (max-width: 1600px) {
  .guides {
    display: flex;
    justify-content: center;
  }

  .guide {
    display: inline-block;
  }

  .guide:nth-child(6n + 3),
  .guide:nth-child(6n + 4) {
    margin: 0;
  }
}

@media only screen and (max-width: 1300px) {
  .section {
    padding: 80px 0;
  }

  .top {
    width: 100%;
    display: grid;
    row-gap: 15px;
    text-align: center;
    justify-content: space-evenly;
  }

  .title {
    font-size: 30px;
    line-height: 35px;
  }

  .subtitle {
    line-height: 27px;
    margin: 0;
  }
}

@media only screen and (hover: none) {
  .guide .overlay {
    opacity: 1;
  }
}
