.background {
  width: 100%;
  height: auto;
  background-image: url('/public/images/request_callback_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: relative;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: #2a3e57de;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 35px;
}

.title {
  font-family: Montserrat, sans-serif;
  font-size: 44px;
  font-weight: 600;
  text-align: start;
  color: #fff;
  position: relative;
  margin-bottom: 26px;
}

.title::before {
  content: '';
  display: block;
  position: absolute;
  width: 3px;
  height: 100%;
  left: -18px;
  padding: 3px 0;
  box-sizing: border-box;
  background-color: #d22524;
}

.subtitle {
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  max-width: 55%;
  /* max-width: 550px; */
}

@media only screen and (max-width: 1499.99px) {
  .title {
    font-size: 35px;
  }
  .subtitle {
    font-size: 19px;
    line-height: 29px;
  }
}

@media only screen and (max-width: 980px) {
  .subtitle {
    max-width: 80%;
    font-size: 18px;
    line-height: 25px;
  }
}


@media only screen and (max-width: 768px) {
  .title {
    text-align: center;
  }
  .subtitle {
    max-width: 90%;
  }
}
