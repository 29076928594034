.container {
  width: 100%;
  background: #0c2340;
  padding-bottom: 26px;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 25px;
}

.left {
  width: 100%;
  height: 100%;
  margin-right: 70px;
  padding-top: 33px;
  border-top: 1px solid #ffffff49;
  display: flex;
  align-items: center;
}

.text {
  color: #fff;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
}

.divider {
  margin: 0 12px;
  display: inline-block;
  width: 1px;
  height: 16px;
  background: #fff;
}

@media only screen and (max-width: 1024px) {
  .text {
    font-size: 13px;
  }
}
@media only screen and (max-width: 840px) {
  .left {
    display: grid;
    row-gap: 10px;
  }
  .divider {
    display: none;
  }
}
@media only screen and (max-width: 780px) {
  .wrapper {
    display: grid;
    row-gap: 30px;
    text-align: center;
    justify-content: center;
  }
  .wrapper img {
    margin: 0 auto;
  }
  .left {
    border: none;
    margin: 0;
  }
  .text {
    font-size: 13px;
  }

}

@media only screen and (max-width: 580px) {
  .responsive {
    width: 100%;
    height: auto;
  }
}