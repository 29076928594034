.container {
  width: 100%;
  background: #0c2340;
  padding: 150px 0 120px;
  display: block;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}

.logo {
  margin-bottom: 11px;
}

.address {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  color: #fff;
}

.telephone {
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  margin-top: 24px;
  margin-bottom: 11px;
}

.email {
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
}


/* navigation */
.title {
  font-family: Montserrat, sans-serif;
  font-size: 23px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 26px;
}

.item {
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  margin-bottom: 18px;
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    row-gap: 50px;
  }
  .container {
    padding: 80px 0;
  }
  .title {
    font-size: 20px;
  }
  .item, .address, .telephone, .email {
    font-size: 13px;
    line-height: 18px;
  }
  .logo {
    max-width: 180px;
  }
}

@media only screen and (max-width: 780px) {
  .wrapper {
    display: grid;
    row-gap: 40px;
    text-align: center;
    justify-content: center;
  }

}