.button {
  width: auto;
  border: none;
  padding: 15px 40px;
  border-radius: 5px;
  text-align: center;

  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: white;

  user-select: none;
  cursor: pointer;
  text-decoration: none;

  transition: 0.3s ease-in-out;
}

.button:hover:not(:disabled) {
  opacity: 0.9;
}

.button:disabled {
  color: #ddd;
}

.blue {
  background-color: #004aa1;
}

.grey {
  background-color: #949ca5;
}

.green {
  background-color: #4BAF4F;
}
