.section {
  width: 100%;
  display: block;
  padding: 80px 0;
  position: relative;
}

.wrapper {
  display: flex;
}

.container {
  width: 100%;
  padding: 0 25px;
  overflow: hidden;
}

.content {
  width: 100%;
  margin: 0 auto;
  max-width: 1500px;
  display: flex;
  gap: 5%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 25px; */
  word-break: break-word;
}

.reversed {
  flex-direction: row-reverse;
}

.primary {
  flex: 6;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;
}

.secondary {
  flex: 4;
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;
}

.primary > img,
.secondary > img {
  width: 100%;
}

/* background colours */
.white {
  background: #fff;
}

.grey {
  background: #f2f3f5;
}

.blue {
  background: #234c7b;
}

@media only screen and (max-width: 1100px) {
  .content {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 980px) {
  .content {
    display: flex;
    flex-direction: column;
  }

  .container {
    padding: 0 50px;
  }

  .secondary {
    max-width: 70%;
    margin: 0 auto;
    display: block;
    margin-bottom: 25px;
  }

}

@media only screen and (max-width: 780px) {
  .content {
    padding: 0;
  }

  .container {
    padding: 0 25px;
  }

  .primary,
  .secondary {
    gap: 1em;
  }
}

@media only screen and (max-width: 480px) {
  .secondary {
    max-width: 100%;
  }
}
