.container {
  width: 100%;
  height: auto;
  background-color: #f7f7f6;
  color: #0c2340;
  margin-bottom: 25px;
  border: 1px solid #e7e7e7;
}

.content {
  width: 80%;
  height: auto;
  padding: 2rem;
  display: grid;
  row-gap: 30px;
}

.top {
  display: flex;
  align-items: center;
  column-gap: 50px;
}

.logo {
  width: 100%;
  max-width: 210px;
  height: auto;
}

.name {
  font-size: 20px;
}


.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
}

.value {
  font-weight: bold;
  margin-top: 5px;
}

.amount {
  font-weight: bold;
  margin-top: 5px;
  color: #d22524;
}

.choose, .other {
  color: #ffffff;
  border-radius: 5px;
  padding: 1rem 2rem;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  transition: .3s ease-in-out;
}

.choose:hover, .other:hover {
  opacity: .9;
}

.choose {
  background-color: #004aa1;
}

.other {
  background-color: #4baf4f;
}

.arrow {
  width: 100%;
  max-width: 30px;
  margin-left: 15px;
}

@media only screen and (max-width: 768px) {
  .top, .row {
    width: 100%;
    display: grid;
    row-gap: 25px;
  }
}
