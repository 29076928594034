.wrapper {
  position: relative;
}

.responsive {
  width: 100%;
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-height: 80%;
  background-image: linear-gradient(0deg, #0c2340ff, #0c234000);
}

.nameAndPositioWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.innerWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.innerWrapper2 {
  padding: 50px;
}

.name, .position {
  width: 100%;
  color: #fff;
  /* position: absolute; */
  line-height: 38px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

.name {
  font-size: 44px;
  font-weight: 600;
  bottom: 110px;
  margin-bottom: 15px;
}

.position {
  font-size: 32px;
  font-weight: 300;
  bottom: 64px;
}

@media only screen and (max-width: 780px) {
  .name {
    font-size: 34px;
  }
  
  .position {
    font-size: 22px;
  }

  .innerWrapper2 {
    padding: 15px 20px;
  }
}
