.pageWrapper {
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  display: flex;
  position: relative;
  line-height: 1.2;
  font-family: Montserrat, sans-serif;
  padding: 3rem 0 5rem;
}

.accessMessage{
  width: 100%;
  display: grid;
  row-gap: 25px;
  padding: 0 25px;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 350px;
}

.row,
.rowPrevNext,
.rowRoute {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0 1rem 0;
  column-gap: 50px;
}

.rowPrevNext {
  margin: 0 25px;
}

.grid {
  width: 100%;
  display: grid;
  row-gap: 25px;
  padding: 0 25px;
}

.spacer {
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: block;
}

.spacer20 {
  width: 100%;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  display: block;
}

.button {
  width: 100%;
  height: 180px;
  border: none;
  color: white;
  padding:35px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  font-size: 54px;
  border-radius: 20px;
  font-weight: 700;
  cursor: pointer;
}

.look {
  color: #fc0000;
  cursor: pointer;
  user-select: none;
}

.button:hover,
.otherContractorBTN:hover,
.darkBlueBTN:hover {
  transition: .3s ease-in-out;
  opacity: .9;
}

.darkBlueBTN {
  background-color: #004aa1;
}

.benchmark, .otherContractorBTN {
  background-color: #4BAF4F;
}

.greyBTN {
  background-color: #949CA5;
}

.otherContractorBTN, .darkBlueBTN, .greyBTN {
  width: auto;
  border: none;
  color: white;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: flex;
  column-gap: 15px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
}

.login, .register {
  width: auto;
  border: none;
  color: #d22524;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
}

.form {
  width: 100%;
  background: #fff;
  border-radius: 0 0 15px 15px;
  padding: 20px 0;
}

.field, .select, .chosenFields {
  width: 50%;
  height: auto;
  padding: .6em 1rem;
  border-radius: 5px;
  background: #f7f7f6;
  border: 2px solid #e6e6e6;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  box-sizing: border-box;
}

.chosenFields {
  color: #949ca5;
}

.select {
  padding-right: 1em;
}

.select:invalid {
  color: #7f7f7f;
}

.goBack {
  color: #949ca5;
  cursor: pointer;
  display: inline-block;
}

.goBack:hover {
  color: #000000;
}

.rowButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0 1rem 0;
  column-gap: 50px;
}

.stepTitle {
  text-transform: uppercase;
  color: #0c2340;
}

.chosenOption {
  font-weight: 800;
  color: #0c2340;
}

.rowEditViewButtons {
  display: flex;
  column-gap: 30px;
}

.arrow {
  width: 100%;
  max-width: 30px;
}

.bold {
  font-weight: bold;
}

.amount {
  display: flex;
  align-items: center;
  column-gap: 20px;
  color: #0c2340;
}

.textBoldRed {
  font-weight: bold;
  color: #d22524;
}

.showResults {
  width: 100%;
}

@media only screen and (max-width: 1124px) {
  .rowRoute {
    row-gap: 25px;
    justify-content: center;
  }
  .button {
    padding: 3px 19px;
    font-size: 40px;
  }
}

@media only screen and (max-width: 920px) {
 .rowRoute {
    width: 100%;
    display: grid;
    row-gap: 25px;
    justify-content: center;
  }

  .select {
    width: 70%;
  }
}

@media only screen and (max-width: 580px) {
  .rowPrevNext {
    flex-direction: column;
    gap: 30px;
    padding: 0rem 0 1rem 0;
  }

  .grid {
    width: 100%;
    row-gap: 25px;
    padding: 0 5px;
  }

  /* .select {
    width: 100%;
  } */
}
