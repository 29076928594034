.container  {
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  margin: 0 auto 8rem;
  padding: 70px 35px 0 35px;
}

.grid {
  width: 100%;
  margin-top: 48px;
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(4, 1fr);
}

.entry {
  height: auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #0c23401a;
  text-decoration: none;
}

.image {
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
}

.responsive {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.details {
  position: relative;
  height: 129px;
  padding: 15px 5px;
}

.title {
  font-size: 26px;
  color: #234C7B;
  line-height: 35px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 10px;

  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.excerpt {
  font-size: 16px;
  color: #0c234094;
  line-height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 10px;
  height: 50px;

  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 15px;
}

.link {
  font-size: 14px;
  color: #004aa1;
  text-decoration: none;
  transition: 0.3s ease-in-out;

  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.entry:hover .link {
  color: #c7131e;
  letter-spacing: 0.5px;
}

.link::after {
  content: ' >';
}

.date {
  font-size: 14px;
  color: #234C7B;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;

  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

@media only screen and (max-width: 1159.99px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width:  640px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
