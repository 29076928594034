.width_100 {
  width: 100%;
  height: auto;
}

.table_wrapper .tr_blue {
  background-color: #004aa1;
  color: #ffffff;
}

.table_wrapper .tr_grey {
  background-color: #f8f8f8;
}

.table_wrapper {
  max-width: calc(100vw - 80px);
  min-width: 350px;
  height: auto;
  margin: 3rem 0 4rem 0;
  display: block;
  position: relative;
  font-family: Montserrat, sans-serif;
}

.table_wrapper table {
  border-spacing: 1px;
}

.table_wrapper table tr {
  background-color: #ffffff;
}

.table_wrapper table tr td {
  padding: 15px 30px;
  margin: 0;
  line-height: 1.5;
  vertical-align: middle;
}

.tableTitle {
  font-size: 18px;
  font-weight: 600;
  min-width: 125px;
}

.check_icon {
  width: 100%;
  max-width: 50px;
  margin: 0 auto;
  display: block;
}

@media only screen and (max-width: 1280px) {
  .table_wrapper {
    overflow-x: auto;
  }

  .table_wrapperInner {
    width: 100%;
  }
}
