.container  {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 1.2;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  padding: 70px 35px 8rem 35px;
}

.row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 30px;
  flex-wrap: wrap;
}


@media only screen and (max-width: 1159.99px) {
  .row {
    justify-content: space-evenly;
  }
}
