.container  {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem 0;
  column-gap: 15px;
}

.logo {
  max-width: 130px;
}

@media only screen and (max-width: 980px) {
  .row {
    display: block;
    text-align: center
  }

  .logo {
    display: inline-block;
    margin: 10px 10px 40px 10px;
  }
}
