.container {
  width: 100%;
  /* max-height: 80px; */
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  column-gap: 8px;
}

.innerContainer {
  width: 100%;
  height: auto;
  /* overflow: hidden;
  display: block; */
}

.breadcrumb {
  display: flex;
  padding: 30px 2px;
  word-wrap: break-word;
}

.crumb {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #949ca5;
  text-decoration: none;
}

.separator::after {
  content: '>>';
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #949ca5;
  padding: 0 10px;
}

.pair {
  display: flex;
}

.active {
  font-weight: 600;
  color: #c7131e;
}

@media only screen and (max-width: 980px) {
  .breadcrumb {
    padding: 20px;
    line-height: 23px;
  }

  .crumb {
    font-size: 14px;
  }
}

@media only screen and (max-width: 580px) {
  .breadcrumb {
    padding: 10px;
  }
}