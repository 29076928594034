.container {
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.label {
  color: #c7131e;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 3.6px;
  display: inline-block;
  margin-right: 10px;
  transition: 0.3s ease-in-out;
}

.label:hover {
  color: #004aa1;
  letter-spacing: 5px;
}

.arrow {
  fill: #c7131e;
}

.blue {
  background-color: #004aa1;
}

.green {
  background-color: green;
}

@media only screen and (max-width: 680px) {
  .label {
    font-size: 90%;
    line-height: 26px;
  }
}

@media only screen and (max-width: 580px) {
  .container {
    width: 25%;
  }

  .label {
    font-size: 12px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 580px) {
  .container {
    width: 100%;
  }
}
