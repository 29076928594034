.ctaWrapper {
  width: auto;
  height: 60px;
  background: #c7131e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0 20px;
  transition: 0.5s;
  text-decoration: none;
}

.ctaWrapper:hover {
  opacity: 0.8;
}

.cta {
  color: #fff;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  transition: 0.5s;
  margin-left: 13px;
  font-weight: 600;
  white-space: nowrap;
}

.cta:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 1430px) {
  .cta {
    font-size: 13px;
  }
}
