.notification {
  padding: 20px;
  margin: 10px 0 10px 0;
}

.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.failed {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}