.heading {
  position: relative;
  display: block;
  margin-left: 28px;
}

.heading::before {
  content: '';
  display: block;
  position: absolute;
  left: -28px;
  width: 4px;
  height: 100%;
  background: #c7131e;
}

.subtitle {
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  margin-bottom: 12px;
}

.title {
  font-weight: 700;
  font-family: Montserrat, sans-serif;
}

.small {
  font-size: 40px;
}

.medium {
  font-size: 44px;
}
.large {
  font-size: 48px;
}

.dark {
  color: #0c2340;
}

.lightBlue {
  color: #234C7B;
}
.white {
  color: #fff;
}

.reference {
  position: absolute;
  top: -191px;
}


@media only screen and (max-width: 1499.99px) {
  .small, .medium {
    font-size: 29px;
  }
  .subtitle {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .small, .medium {
    font-size: 25px;
    line-height: 27px;
  }

  .large {
    font-size: 23px;
  }

  .subtitle {
    font-size: 13px;
    line-height: 1.2;
    max-width: 80%;
  }
}

@media only screen and (max-width: 375px) {
  .small, .medium {
    font-size: 22px;
    line-height: 22px;
  }

  .large {
    font-size: 20px;
  }

  .subtitle {
    font-size: 11px;
    line-height: 1.2;
    max-width: 80%;
  }
}
