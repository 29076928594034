.container {
  width: 100%;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0 1rem 0;
  gap: 50px;
}

.button {
  width: 100%;
  height: 180px;
  border: none;
  color: white;
  padding: 35px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  font-size: 54px;
  border-radius: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.button:hover {
  opacity: 0.9;
}

.contractor {
  background-color: #234671;
}

.benchmark {
  background-color: #4BAF4F;
}

@media only screen and (max-width: 1214px) {
  .buttons {
    flex-wrap: wrap;
    /* max-width: 90%; */
    justify-content: center;
  }

  .button {
    width: 80%;
    padding: 15px 25px;
    font-size: 35px;
    height: 150px;
  }
}

@media only screen and (max-width: 930px) {
  .button svg {
    width: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .button {
    font-size: 17px;
    height: 75px;
  }
  
  .button svg {
    width: 50px;
  }
}

@media only screen and (max-width: 320px) {
  .buttons {
    padding: 0rem 0 1rem 0;
  }
}