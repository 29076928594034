.container {
  padding: 142px;
  padding-right: 119px;
}

.form {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  padding: 0.5rem 0 1.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
}

.login {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #818181;
}

.link {
  color: #fc0000;
  text-decoration: none;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  column-gap: 74px;
  row-gap: 37px;
}

.field {
  width: calc(50% - 74px / 2);
}

.label {
  display: block;
  color: #949ca5;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  margin-bottom: 12px;
}

.input {
  width: 100%;
  background: #f7f7f6;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  padding: 22px 16px;
  box-sizing: border-box;
}

.error {
  color: #c7131e;
}

.submit {
  margin-top: 33px;
  background: #004aa1;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 13px 25px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  transition: 300ms;
}

.submit:hover {
  opacity: 0.9;
}

.subtitle {
  font-size: 16px;
  line-height: 20px;
  font-family: Montserrat, sans-serif;
  color: #0c2340;
  margin-top: 26px;
  margin-bottom: 26px;
}

.strong {
  font-weight: bold;
}

.link {
  color: #000;
  text-decoration: underline;
}
.link:hover {
  text-decoration: none;
}

@media only screen and (max-width: 1300px) {
  .container {
    padding: 100px 50px;
  }
}

@media only screen and (max-width: 980px) {
  .container {
    padding: 80px 35px;
    display: grid;
  }
  .fields {
    display: flex;
  }
  .input {
    padding: 14px;
  }
}

@media only screen and (max-width:768px) {
  .fields {
    display: grid;
  }
  .field {
    width: 100%;
  }
}

@media only screen and (max-width:480px) {
  .header {
    display: grid;
    row-gap: 10px;
  }
}
