.container {
  padding: 142px;
  padding-right: 119px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.login {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #818181;
}

.link {
  color: #fc0000;
  text-decoration: none;
}

.subtitle {
  font-size: 22px;
  font-family: Montserrat, sans-serif;
  color: #0c2340;
  margin-top: 26px;
  margin-bottom: 52px;
}

@media only screen and (max-width: 1300px) {
  .container {
    padding: 100px 50px;
  }
}

@media only screen and (max-width: 980px) {
  .container {
    padding: 80px 35px;
    display: grid;
  }
}

@media only screen and (max-width:480px) {
  .header {
    display: grid;
    row-gap: 10px;
  }
}
