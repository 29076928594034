.container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
}

.left {
  width: calc(100% / 3);
  display: flex;
  position: relative;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: #2a3e57de;
  position: absolute;
}

.logo {
  width: 100%;
  height: auto;
  max-width: 218px;
  position: absolute;
  left: 42px;
  top: 30px;
}

.bottom {
  position: absolute;
  display: flex;
  left: 42px;
  bottom: 30px;
}

.legal {
  color: #fff;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
}

.divider {
  margin: 0 12px;
  display: inline-block;
  width: 1px;
  height: 16px;
  background: #fff;
}

.right {
  width: calc(100% / 3 * 2);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 980px) {
  .container {
    width: 100%;
    display: grid;
  }
  .left, .right {
    width: 100%;
  }
  .left {
    max-height: 300px;
  }
  .bottom {
    align-items: center;
    left: 50%;
    transform: translate(-50%, 10%);
  }
  .legal {
    font-size: 11px;
  }
  .logo {
    left: 50%;
    transform: translate(-50%, 10%);
  }
}
