.label {
  padding-bottom: 20px;
  display: block;
}

.period {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 40px;
}

.question {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin-bottom: 8px;
  display: inline-block;
}

.help {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: italic;
}

.error {
  color: #c7131e;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.link {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: #c7131e;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
}
