.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.label {
  padding-bottom: 20px;
  display: block;
}

.question {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin-bottom: 8px;
  display: inline-block;
}

.help {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: italic;
}

.error {
  color: #c7131e;
}

.buttons {
  display: flex;
  justify-content: space-between;
}
